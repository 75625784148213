import { useDispatch, useSelector } from "react-redux";
import { 
    Box, Fab, LinearProgress, Paper, Toolbar, Typography, Autocomplete, TextField,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useEffect, useState } from "react";
import {
    MANAGER_DASH_LOAD, MANAGER_SEARCH_KEYWORDS, SET_MANAGER_SEARCHED_KD, ASSIGN_KEYWORD_TO_USER, 
    MANAGER_CREATE_KD, UPDATE_MANAGER_NEW_KW_DIALOG, UPDATE_MANAGER_ASSIGN_DIALOG, MANAGER_CHECK_KD_EXIST
} from "../../actions/manager";
import { LOAD_USERS } from "../../actions/admin";
import { Board } from "../../components";
import SearchIcon from '@mui/icons-material/Search';

export function ManagerDash() {
    const dispatch = useDispatch();
    const loading = useSelector(state=>state.manager.loading);
    const boards = useSelector(state=>state.manager.boardsIdx);
    const project = useSelector(state=>state.system.selectedProject);
    const keywordsOpt = useSelector(state=>state.manager.keywordsOpt);
    const dialogLoading = useSelector(state => state.manager.settingLoading);
    const projects = useSelector(state=>state.system.projects);
    const onNewKeywordDialog = useSelector(state => state.manager.onNewKeywordDialog);
    const onAssignDialog = useSelector(state => state.manager.onAssignDialog);
    const users = useSelector(state => state.admin.users);
    const keywordDetail = useSelector(state=>state.manager.keywordDetail);

    useEffect(()=>{
        dispatch({type: MANAGER_DASH_LOAD})
        dispatch({type: LOAD_USERS})
    }, [dispatch, project])

    const [reassignUser, setReassignUser] = useState({});
    const [selectedKeyword, setSelectedKeyword] = useState({});
    const offAssignDialog = (event)=>{
        event.stopPropagation();
        dispatch({type: UPDATE_MANAGER_ASSIGN_DIALOG, status: false});
        setReassignUser({});
    }

    const offNewKeywordDialog = (event)=>{
        event.stopPropagation();
        dispatch({type: UPDATE_MANAGER_NEW_KW_DIALOG, status: false});
    }
    useEffect(() => {
        setSelectedKeyword(keywordDetail);
    }, [keywordDetail]);

    return <Paper sx={{flexGrow: 1, display: "flex"}}>
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <Toolbar sx={{ bgcolor: "primary.light", display: "flex", justifyContent: "space-between"}}
                     variant="dense">
                <Typography variant="h5">Dashboard</Typography>
                <Box sx={{width: "300px", display: "flex", alignItems: "center"}}>
                    <SearchIcon/>
                    <Autocomplete
                        freeSolo
                        sx={{
                            "& .MuiInputBase-formControl": {
                                paddingTop: "0px",
                                paddingBottom: "0px"
                            }
                        }}
                        fullWidth
                        onInputChange={(e, v, r) => {
                            if(e) {
                                dispatch({type: MANAGER_SEARCH_KEYWORDS, search: v});
                            }
                        }}
                        onChange={(e, value)=>{
                            e.preventDefault();
                            // if(value) {
                            //     setSelectedKeyword(value);
                            //     if(value.keywordDetailData) {
                            //         // setOnAssignDialog(true);
                            //         dispatch({type: UPDATE_MANAGER_ASSIGN_DIALOG, status: true});
                            //         const kd = value.keywordDetailData ? value.keywordDetailData : {};
                            //         dispatch({type: SET_MANAGER_SEARCHED_KD, payload: {keywordDetail: kd, keywordId: value.id}});
                            //     }else {
                            //         dispatch({type: UPDATE_MANAGER_NEW_KW_DIALOG, status: true});
                            //     }      
                            // }
                            if(value) {
                                dispatch({type: MANAGER_CHECK_KD_EXIST, payload: value})
                            }
                        }}
                        // disableClearable
                        options={keywordsOpt || []}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option?.id === value.id}
                        renderInput={
                            (params) => 
                            <TextField
                                sx={{bgcolor: "rgba(255, 255, 255, 0.15)"}}
                                {...params}
                                placeholder="Search for Keyword"
                                InputProps={
                                    {
                                        ...params.InputProps,
                                    }}
                            />
                        }
                    />
                </Box>
                <Box>
                    <Fab size="small"
                         color="info"
                         variant={"extended"}
                         onClick={event => dispatch({ type: MANAGER_DASH_LOAD })}
                    >
                        <RefreshIcon sx={{ marginRight: "0.25rem" }}/>Refresh
                    </Fab>
                </Box>
            </Toolbar>
            <Box sx={{flexGrow: 0, height: "4px"}}>
                {loading && <LinearProgress variant="indeterminate" color="primary"/>}
            </Box>
            <Box sx={{ flexGrow: 1, display: "flex", justifyItems: "even-spacing" }}>
                {
                    boards.map(board=> (
                        <Board key={`b-${board}`} selector={`manager.boardsData.${board}`} itemHeight={160}/>
                    ))
                }
            </Box>
        </Box>
        <Dialog open={onAssignDialog} onClose={offAssignDialog}>
            <DialogTitle sx={{fontSize: 'large'}}>Assign Keyword</DialogTitle>
            {dialogLoading && <LinearProgress variant="indeterminate" color="primary"/>}
            <DialogContent>
                <DialogContentText sx={{padding: "10px"}}>
                    <Box sx={{paddingBottom: "10px", display: "flex"}}>
                        <Typography sx={{fontSize: 'medium', fontWeight: "bold", paddingRight: "5px"}}>
                            Name:
                        </Typography>
                        <Typography sx={{fontSize: 'medium', paddingRight: "5px"}}>
                            {selectedKeyword?.displayName}
                        </Typography>
                    </Box>
                    <Box sx={{display: "flex"}}>
                        <Typography sx={{fontSize: 'medium', fontWeight: "bold", paddingRight: "5px"}} >
                            Assignee:
                        </Typography>
                        <Autocomplete
                            sx={{
                                width: "150px",
                                "& .MuiOutlinedInput-root": {
                                    padding: "0px",
                                    "& .MuiAutocomplete-input": {
                                        padding: "2px 2px 2px 4px"
                                    }
                                },
                            }}
                            fullWidth
                            onChange={(e, value)=>{
                                e.preventDefault();
                                if(value) {
                                    setReassignUser(value);
                                }
                            }}
                            disableClearable
                            options={Object.values(users).filter(x => x.userId !== selectedKeyword?.taskAssigned) || []}
                            getOptionLabel={(option) => option?.name || ""}
                            value={(reassignUser?.userId ? users[reassignUser?.userId] : users[selectedKeyword?.taskAssigned]) || {}}
                            renderInput={
                                (params) => <TextField
                                    {...params}
                                    placeholder="Select User"
                                    InputProps={
                                        {
                                            ...params.InputProps,
                                        }}
                                />
                            }
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: ASSIGN_KEYWORD_TO_USER, payload: {userId: reassignUser.userId, updKeywordOptList: true} });
                }}>Confirm</Button>
                <Button onClick={offAssignDialog}>Dismiss</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={onNewKeywordDialog} onClose={offNewKeywordDialog}>
            <DialogTitle sx={{fontSize: 'large'}}>Keyword Not Found!</DialogTitle>
            {dialogLoading && <LinearProgress variant="indeterminate" color="primary"/>}
            <DialogContent>
                <DialogContentText sx={{padding: "10px"}}>
                    Keyword is not exists for this channel. Do you want add this keyword to this channel?
                    <Box sx={{padding: "20px"}}>
                        <Box sx={{display:"flex"}}>
                            <Typography sx={{fontWeight: "bold", paddingRight: "5px"}}>Keyword ID: </Typography><Typography>{selectedKeyword.id}</Typography>
                        </Box>
                        <Box sx={{display:"flex"}}>
                            <Typography sx={{fontWeight: "bold", paddingRight: "5px"}}>Keyword Name: </Typography><Typography>{selectedKeyword.name}</Typography>
                        </Box>
                        <Box sx={{display:"flex"}}>
                            <Typography sx={{fontWeight: "bold", paddingRight: "5px"}}>Channel: </Typography><Typography>{projects[project]?.name}</Typography>
                        </Box>
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: MANAGER_CREATE_KD, payload: {kwId: selectedKeyword.id} });
                }}>Yes</Button>
                <Button onClick={offNewKeywordDialog}>No</Button>
            </DialogActions>
        </Dialog>
    </Paper>
}