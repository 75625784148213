import { createReducer } from "@reduxjs/toolkit";
import {
    ADMIN_DASH_FAIL,
    ADMIN_DASH_LOAD, ADMIN_DASH_LOADED,
    ADMIN_RESET,
    EDIT_USER_INFO,
    LOAD_USERS,
    LOAD_USERS_SUCCESS, SAVE_USER, SAVE_USER_FAIL, SAVE_USER_SUCCESS,
    UPDATE_USER_MANAGER,
    UPDATE_USER_PROJECTS,
    SELECT_USER_INFO, SELECT_USER_INFO_SUCCESS,
    UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL
} from "../actions/admin";
import { createVibes } from "@cosmos/vibes";
import { keyBy } from "lodash";

export const adminReducer = createReducer({
    loading: false,
    processing: false,
    code: 0,
    users: {},
    userIdx: [],
    userInfo: {
        userId: -1,
        username: "",
        name: "",
        email: "",
        manager: null,
        projects: []
    },
    boardsIdx: ["preview", "delegate", "processing", "approval", "publish", "latest"],
    boardsData: {
        preview: {
            title: "Ready for Preview",
            desc: "Keyword have scraped content that ready for preview",
            items: [],
            code: "#f89e5d"
        },
        delegate: {
            title: "Ready to Delegate",
            desc: "Keyword already have content which ready assign to writer",
            items: [],
            code: "#fddd43"
        },
        processing: {
            title: "In progress",
            desc: "Keywords in writing content stage",
            items: [],
            code: "#7f5efc"
        },
        approval: {
            title: "Pending Approval",
            desc: "Keyword have content that already edit waiting for approve",
            items: [],
            code: "#6da4f8"
        },
        provision: {
            title: "Pending Provision",
            desc: "Keyword have content that approved waiting for provision",
            items: [],
            code: "#19d3f1"
        },
        publish: {
            title: "Published",
            desc: "Keyword already provisioned, waiting publish to live site",
            items: [],
            code: "#82fc82"
        },
        latest: {
            title: "Latest Launched",
            desc: "Keyword that launched within last 7 days",
            items: [],
            code: "#46fc09"
        }
    }
}, builder => {
    builder.addCase(LOAD_USERS, state => {
        state.loading = true;
    }).addCase(LOAD_USERS_SUCCESS, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.userIdx = action.payload.userIdx;
    }).addCase(EDIT_USER_INFO, (state, action) => {
        state.userInfo[action.path] = action.payload;
    }).addCase(UPDATE_USER_MANAGER, (state, action) => {
        state.userInfo.manager = action.payload;
    }).addCase(UPDATE_USER_PROJECTS, (state, action) => {
        state.userInfo.projects = action.payload;
    }).addCase(SAVE_USER, state => {
        state.processing = true;
    }).addCase(SAVE_USER_SUCCESS, (state, action) => {

        state.processing = false;
        state.code = 1;
        state.users[action.payload.userId] = action.payload;
        state.userIdx.unshift(action.payload.userId);
    }).addCase(SAVE_USER_FAIL, (state, action) => {
        state.processing = false;
        state.code = -1;
    }).addCase(ADMIN_RESET, (state, action) => {
        if (state.code > 0 || action.isReset) {
            state.userInfo = {
                userId: -1,
                username: "",
                name: "",
                email: "",
                manager: null,//state.userInfo.manager,
                projects: []//state.userInfo.projects
            };
        }
        state.code = 0;
    }).addCase(ADMIN_DASH_LOAD, (state) => {
        state.loading = true;
    }).addCase(ADMIN_DASH_FAIL, (state) => {
        state.loading = false;
    }).addCase(ADMIN_DASH_LOADED, (state, action) => {
        state.loading = false;
        state.boardsData.preview.items = action.payload.preview;
        state.boardsData.delegate.items = action.payload.delegate;
        state.boardsData.processing.items = action.payload.processing;
        state.boardsData.approval.items = action.payload.approval;
        state.boardsData.provision.items = action.payload.provision;
        state.boardsData.publish.items = action.payload.publish;
        state.boardsData.latest.items = action.payload.latest;
    }).addCase(SELECT_USER_INFO_SUCCESS, (state, action) => {
        state.userInfo.userId = action.payload.userId;
        state.userInfo.name = action.payload.name;
        state.userInfo.manager = action.payload.manager;
    })
    .addCase(UPDATE_USER, (state, action) => {
        state.processing = true;
        state.code = 1;
    }).addCase(UPDATE_USER_SUCCESS, (state, action) => {
        state.processing = false;
        state.code = 1;
    }).addCase(UPDATE_USER_FAIL, (state, action) => {
        state.processing = false;
        state.code = -1;
    });
});

export const loadUsers = createVibes({
    type: LOAD_USERS,
    latest: true,
    processOptions: {
        successType: LOAD_USERS_SUCCESS
    },
    async process({ axios }) {
        const { data } = await axios({
            method: "post",
            data: {
                query: `query loadUsers {
                    users {
                        name,
                        userId,
                        manager
                    }
                }`
            }
        });
        const { users } = data;
        const userIdx = users.map(u => u.userId);
        return { users: keyBy(users, "userId"), userIdx };
    }
});

export const addUser = createVibes({
    type: SAVE_USER,
    processOptions: {
        successType: SAVE_USER_SUCCESS,
        failType: SAVE_USER_FAIL
    },
    async process({ getState, axios }) {
        const { userInfo } = getState().admin;
        const user = {
            name: userInfo.username,
            fullname: userInfo.name,
            email: userInfo.email,
            system: 2,
            roles: [12003],
            permissions: userInfo.projects.map(p => p.permission)
        };
        if (!userInfo.manager) user.roles.push(12002);
        console.log("User", user);
        const { data: res1 } = await axios({
            method: "post",
            url: `${process.env.REACT_APP_AUTH_DOMAIN}/v1/api`,
            data: {
                query: `mutation addUser($user:UserInput){ addUser(input: $user){id name}}`,
                variables: { user }
            }
        });
        if (res1 && res1.addUser) {
            const profile = {
                userId: res1.addUser.id,
                name: userInfo.name,
                manager: userInfo.manager?.userId || 0
            };
            if (user.manager) profile.manager = user.manager.userId;
            const { data: res2 } = await axios.request({
                method: "post",
                data: {
                    query: `mutation($profile:UserInput){createUser(input: $profile) {userId name manager}}`,
                    variables: { profile }
                }
            });
            if (res2 && res2.createUser) {
                return res2.createUser;
            } else {
                throw new Error("Profile create with error");
            }
        }
        throw new Error("Vault create with error");
    }
});

export const loadAdminDash = createVibes({
    type: ADMIN_DASH_LOAD,
    latest: true,
    processOptions: {
        successType: ADMIN_DASH_LOADED,
        failType: ADMIN_DASH_FAIL
    },
    async process({ getState, axios }) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];

        const { data } = await axios.request({
            method: "POST",
            data: {
                query: `query admin_dash($channel: String) {
                    preview: readyPreview(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                    delegate: readyDelegate(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                    processing(channel: $channel) {
                        id keyword volume potential difficulty contents inventory channeled available provisioned lived 
                    }
                    approval: readyApproval(channel: $channel) {
                        id keyword volume potential difficulty contents inventory channeled available provisioned lived 
                    }
                    provision: readyProvision(channel: $channel) {
                        id keyword volume potential difficulty contents inventory channeled available provisioned lived
                    }
                    publish: readyPublish(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                    latest: latestPublish(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                }`,
                variables: {
                    channel: project.channel
                }
            }
        });
        return data;
    }
});

export const selectUser = createVibes({
    type: SELECT_USER_INFO,
    processOptions: {
        successType: SELECT_USER_INFO_SUCCESS
    },
    async process({ action, getState, axios }) {
        const users = getState().admin.users;
        // maybe need to query and get current assigned project from userprofilepermission
        // const permissions = [{userProfileId: 111, permissionId: 222}]
        // permissionId -> permission
        return {
            userId: action.payload.userId, 
            manager: action.payload.manager ? users[action.payload.manager] : null,
            name: action.payload.name 
        }
    }
});

export const updateUser = createVibes({
    type: UPDATE_USER,
    processOptions: {
        successType: UPDATE_USER_SUCCESS,
        failType: UPDATE_USER_FAIL
    },
    async process({ action, getState, axios }) {
        const { userInfo } = getState().admin;
        const user = {
            id: userInfo.userId,
            permissions: userInfo.projects.map(p => p.permission)
        }
        console.log("Update user", user)
        const { data: res } = await axios({
            method: "post",
            url: `${process.env.REACT_APP_AUTH_DOMAIN}/v1/api`,
            data: {
                query: `mutation editUser($user:UserInput){ grantPermission(input: $user){id name}}`,
                variables: { user }
            }
        });
        // const res = {editUser: 1}
        if (res && res.editUser) {
            return user
        }
    }
});
