import {createReducer} from "@reduxjs/toolkit";
import {
    SET_SOURCING_UPLOAD_PRIMARY,
    UPLOAD_SOURCING_IMAGE,
    UPLOAD_SOURCING_IMAGE_SUCCESS,
    UPLOAD_SOURCING_IMAGE_FAIL,
    UPLOAD_SOURCING,
    UPLOAD_SOURCING_SUCCESS,
    UPLOAD_SOURCING_FAIL,
    UPLOAD_SOURCING_CLOSE_NOTIFICATION,
    PREPARE_SOURCING_IMAGE,
    PREPARE_SOURCING_IMAGE_SUCCESS,
    LOAD_SOURCING_BASE_KW_LIST,
    LOAD_SOURCING_BASE_KW_LIST_SUCCESS,
    SET_SOURCING_ISORIGINAL_FLAG,
    SET_SOURCING_UPLOAD_ISZIP_FLAG,
    PREPARE_SOURCING_ZIP, PREPARE_SOURCING_ZIP_SUCCESS,
    UNZIP_SOURCING, UNZIP_SOURCING_SUCCESS, UNZIP_SOURCING_FAIL,
    UPLOAD_SOURCING_ZIP_TO_S3, UPLOAD_SOURCING_ZIP_TO_S3_SUCCESS
} from "../actions/sourcing";
import {createVibes} from "@cosmos/vibes";
import { customAlphabet } from "nanoid";
import AWS from 'aws-sdk';
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 16);
const S3_BUCKET = 'pixel-upload';
const REGION = 'ap-southeast-1';

AWS.config.update({
    region: 'ap-southeast-1',
    accessKeyId: 'AKIAZBWVTGULTGEJBUTI',
    secretAccessKey: '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'
})


export const sourcingReducer = createReducer(
    {
        keyword: {
            id: "",
            name: ""
        },
        imgArr: [],
        failImgArr: [],
        duplicateImgArr: [],
        loading: false,
        notice: "",
        notify: false,
        noticeType: "error",
        bases: [],
        primaryBaseKeyword: {
            id: "",
            name: ""
        },
        isOriginal: false,
        baseKeywords: [],
        isZip: false,
        allZipFiles: [],
        opLoading: false
    }
    ,
    builder => {
        builder.addCase(SET_SOURCING_UPLOAD_PRIMARY, (state, action) => {
            state.keyword = action.payload.data;
            state.primaryBaseKeyword.id = action.payload.data.base_id;
            state.primaryBaseKeyword.name = action.payload.data.base;
        }).addCase(PREPARE_SOURCING_IMAGE_SUCCESS, (state, action) => {
            state.imgArr = action.payload;
        })
        .addCase(LOAD_SOURCING_BASE_KW_LIST, (state) => {
            state.opLoading = true;
        })
        .addCase(UPLOAD_SOURCING_IMAGE, (state) => {
            state.loading = true;
        })
        .addCase(UPLOAD_SOURCING_IMAGE_SUCCESS, (state, action) => {
            state.loading = false;
            let imgArr = state.imgArr;
            imgArr.map(x => {
                if(x.fileId === action.payload)
                    x.uploaded = true
            })
            state.imgArr = imgArr;
        }).addCase(UPLOAD_SOURCING_IMAGE_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload;
            state.noticeType = "error"
        }).addCase(UPLOAD_SOURCING, (state) => {
            state.loading = true;
        }).addCase(UPLOAD_SOURCING_SUCCESS, (state, action) => {
            state.loading = false;
            state.keyword = {
                id: "",
                name: ""
            }
            state.primaryBaseKeyword = {
                id: "",
                name: ""
            }
            state.isOriginal = false;
            state.imgArr = [];
            state.notify = true;
            state.notice = "Successfully upload source";
            state.noticeType = "success";
            state.isZip = false;
            state.allZipFiles = [];
            state.failImgArr = [];
            state.duplicateImgArr = [];
        }).addCase(UPLOAD_SOURCING_FAIL, (state, action) => {
            state.loading = false;
            state.notify = true;
            state.notice = action.payload;
            state.noticeType = "error"
        }).addCase(UPLOAD_SOURCING_CLOSE_NOTIFICATION, (state, action) => {
            state.notify = false;
            state.notice = "";
            state.noticeType = "error"
        }).addCase(LOAD_SOURCING_BASE_KW_LIST_SUCCESS, (state, action) => {
            state.opLoading = false;
            state.baseKeywords = action.payload;
        }).addCase(SET_SOURCING_ISORIGINAL_FLAG, (state, action) => {
            state.isOriginal = action.payload.original
        })
        .addCase(SET_SOURCING_UPLOAD_ISZIP_FLAG, (state, action) => {
            state.isZip = action.payload;
            state.keyword = {
                id: "",
                name: ""
            }
            state.primaryBaseKeyword = {
                id: "",
                name: ""
            }
            state.isOriginal = false;
            state.imgArr = [];
            state.allZipFiles = [];
            state.failImgArr = [];
            state.duplicateImgArr = [];
        })
        .addCase(PREPARE_SOURCING_ZIP, (state, action) => {
            state.loading = true;
        })
        .addCase(PREPARE_SOURCING_ZIP_SUCCESS, (state, action) => {
            state.loading = false;
            let upploadFiles = action.payload?.uploadFiles;
            if(action.payload.uploadFiles && action.payload.uploadFiles.length >0) {
                upploadFiles = upploadFiles.map(x=> {
                    x.uploaded = true;
                    return x
                })
            }
            state.imgArr = upploadFiles;
            // state.failImgArr = action.payload?.failUploadFiles;
            // state.duplicateImgArr = action.payload?.duplicateFiles;
            state.notify = true;
            state.notice = "Successfully upload content";
            state.noticeType = "success";
        })
        .addCase(UNZIP_SOURCING_SUCCESS, (state, action) => {
            const unzipInfo = action.payload.payload;
            state.loading = false;
            let imgArr = state.imgArr;
            imgArr.map(x => {
                if(x.name == unzipInfo.name) {
                    x.uploaded = true;
                    x.matchRecords = unzipInfo.matchRecords;
                    x.unMatchFiles = unzipInfo.unMatchFiles;
                }
            })
            state.imgArr = imgArr;
            let allZipFiles = state.allZipFiles;
            state.allZipFiles = allZipFiles.concat(unzipInfo.matchRecords);
        })
        .addCase(UPLOAD_SOURCING_ZIP_TO_S3, (state, action) => {
            state.loading = true;
        })
        .addCase(UPLOAD_SOURCING_ZIP_TO_S3_SUCCESS, (state, action) => {
            state.loading = false;
            const data = action.payload;
            state.imgArr = [{...data, uploaded: true}];
        })

    }
)

export const prepareSourcingImage = createVibes({
    type: PREPARE_SOURCING_IMAGE,
    async process({action}, dispatch, done) {
        const fileData = action.payload;

        const storeData = [];
        const uploadData = [];
        for (const file of fileData) {
            const fileName = nanoid();
            // store to redux store
            let storeObj = {name: file.name, fileId: fileName, type: file.type, uploaded: false}
            storeData.push(storeObj);

            // pass to S3 for upload 
            let fileObj = {...storeObj, body: file}
            uploadData.push(fileObj);
        }

        dispatch({ type: PREPARE_SOURCING_IMAGE_SUCCESS, payload: storeData });
        dispatch({ type: UPLOAD_SOURCING_IMAGE, payload: uploadData });
        done();
    }
});

export const uploadSourcingImage = createVibes({
    type: UPLOAD_SOURCING_IMAGE,
    async process({action}, dispatch, done) {
        for (const file of action.payload) {
            // // if(process.env.NODE_ENV == "development") {
            // //     // If is development treat upload success
                dispatch({ type: UPLOAD_SOURCING_IMAGE_SUCCESS, payload: file.fileId });
            // // }else {
            //     // If is production, upload to S3
            //     var upload = new AWS.S3.ManagedUpload({
            //         params: {
            //             Bucket: S3_BUCKET,
            //             Key: file.fileId,
            //             Body: file.body
            //         }
            //     });
    
            //     var promise = upload.promise();
    
            //     promise.then(
            //         function(data) {
            //             console.log("Successfully uploaded photo.");
            //             dispatch({ type: UPLOAD_SOURCING_IMAGE_SUCCESS, payload: data.key });
            //         },
            //         function(err) {
            //             return console.log("There was an error uploading your photo: ", err.message);
            //         }
            //     );
            // // }
        }
    }
});

export const uploadSourcing = createVibes({
    type: UPLOAD_SOURCING,
    latest: true,
    processOptions: {
        successType: UPLOAD_SOURCING_SUCCESS,
        failType: UPLOAD_SOURCING_FAIL
    },
    async process({ action,getState, axios }) {
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const keyword = state.sourcing.keyword;
        const baseId = state.sourcing.primaryBaseKeyword.id;
        const isOriginal = state.sourcing.isOriginal;
        let imgArr = state.sourcing.imgArr;
        const isZip = state.sourcing.isZip;
        if(isZip) {
            imgArr = state.sourcing.allZipFiles;
        }
        let inputArr = []
        for (const x of imgArr) {
            let input = {
                kwId: keyword.id,
                keyword: keyword.name,
                source: "pixel",
                format: x.type,
                isOriginal: isOriginal ? 10 : 0,
                fileId: x.fileId
            }
            if (channel) {
                if (channel !== "200001"){
                    input.type = 0
                } else {
                    input.type = 1
                }
            }
            inputArr.push(input)
        }

        const res = await axios.request({
            method: "POST",
            data: {
                query: `mutation uploadContent($inputs: [UploadSourceContentInput], $baseId: Int, $channel: String) {
                    uploadContentList(inputs: $inputs baseId: $baseId, channel: $channel)
                }`,
                variables: {
                    inputs: inputArr,
                    baseId,
                    channel
                }
            }
        });

        if(res && res.data && res.data?.uploadContentList > 0) {
            return res.data?.uploadContentList
        }else {
            throw ("Failed to upload contents"); 
        }
    }
});

export const loadSourcingBaseList = createVibes({
    type: LOAD_SOURCING_BASE_KW_LIST,
    latest: true,
    processOptions: {
        successType: LOAD_SOURCING_BASE_KW_LIST_SUCCESS,
    },
    async process({ getState, axios, action }) {
        const { data: responseData } = await axios.request({
            method: "post",
            data: {
                query:  `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                        id
                        name
                        slug
                    }
                    bases {id name}
                }
                `,
                variables: {
                    filter: {
                        search: action.search
                    }
                }
            },
        });
        let { keywords, bases } = responseData;
        // combine keyword with base => keyword [base]
        let combineKeywords = [];
        keywords.map(keyword => {
            bases.map(base => {
                combineKeywords.push({
                    name: keyword.name + " [" + base.name + "]",
                    base_id: base.id,
                    base: base.name,
                    id: keyword.id,
                    keywordName: keyword.name
                })
            })
        })

        return combineKeywords;
    }
});

// async function sleep(millis) {
//     return new Promise(resolve => setTimeout(resolve, millis));
// }

export const prepareSourcingZip = createVibes({
    type: PREPARE_SOURCING_ZIP,
    async process({getState, action, axios}, dispatch, done) {
        // const fileData = action.payload;
        const state = getState();
        const selected = state.system.selectedProject;
        const project = state.system.projects[selected];
        const channel = project.channel;
        const keywordId = state.sourcing.keyword.id;
        const keywordName = state.sourcing.keyword.keywordName;
        const baseId = state.sourcing.keyword.base_id;
        const zipFileId = state.sourcing.imgArr[0].fileId;

        const isOriginalValue = state.sourcing.isOriginal ? 10 : 0;

        const formData = new FormData();        
        formData.append("channel", channel); //required for sourcing screen only
        formData.append("keywordId", keywordId);
        // formData.append("keywordName", keywordName);
        formData.append("baseId", baseId);
        formData.append("isOriginal", isOriginalValue);
        formData.append("zipFileId", zipFileId);

        // for(let i =0; i < action.payload.length; i++) {
        //     formData.append("files", action.payload[i]);
        // }

        const res = await axios.post(
            "upload/zip",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },              
            }
        );

        if(res && res.status === 200 && res.data) {
            dispatch({ type: PREPARE_SOURCING_ZIP_SUCCESS, payload: res.data });
        }
    }
});

export const unzipSourcing = createVibes({
    type: UNZIP_SOURCING,
    processOptions: {
        successType: UNZIP_SOURCING_SUCCESS,
        failType: UNZIP_SOURCING_FAIL
    },
    async process({ getState, axios, action }, dispatch, done) {
        const res = await axios.post(
            "upload/zip",
            {fileData: action.payload},
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },                    
            }
        );

        if(res && res.status === 200 && res.data) {
            await sleep(500);
            dispatch({ type: UNZIP_SOURCING_SUCCESS, payload: res.data });
        }
        done();
    }
});

export const uploadSourcingZipToS3 = createVibes({
    type: UPLOAD_SOURCING_ZIP_TO_S3,
    async process({action}, dispatch, done) {
        const file = action.payload[0];
        const fileName = file.name;

        const fileId = nanoid();
        const myBucket = new AWS.S3({
            params: { Bucket: S3_BUCKET},
            region: REGION,
        })

        const params = {
            // ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: fileId,
            ContentType: 'application/zip',
        };

        myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            if(evt.loaded === evt.total) {
                dispatch({ type: UPLOAD_SOURCING_ZIP_TO_S3_SUCCESS, payload: {fileId, name: fileName} });
            }
        })
        .send((err) => {
            if (err) console.log(err)
        })
    }
});
