import {
    systemInitVibes,
    loadProjects,
    getTotalNumber,
    submitKeywordChecker,
    loadUsers,
    addUser,
    selectUser, updateUser,
    loadAdminDash,
    loadContents,
    filterContents,
    filterTask,
    loadContentDetail,
    updateContent,
    submitTask, provisionContent, approveContent, setFeaturedContent,
    loadAltContents,
    loadBaseKeywords,
    loadPreviewKWList, loadSCList, downloadSourceContentData, submitSourceContentData, managerSearchSCKeyword,
    fetchPreviewKeywordsFromServer,
    deselectContent, loadKeywordsList,
    linkContentToKeyword, unlinkContentFromKeyword, setContentPrimary, setContentOriginal,
    searchContentBySlug, searchTask,
    loadTaskUsers, selectUserTasks, loadUnassignedKeyword, assignTaskUser,
    prepareContentImage, uploadContentImage, uploadContent, addToTagCloud,
    prepareSourcingImage, uploadSourcingImage, uploadSourcing, selectContents,
    setTaskFromManager, editorSearchKeyword,
    loadUploadBaseList, loadSourcingBaseList,
    prepareContentZip, unzipContent, uploadContentZipToS3,
    prepareSourcingZip, unzipSourcing, uploadSourcingZipToS3
} from "./features";
import { authLogin, authRefresh, authLogout } from "@cosmos/runes";
import { loadTasks } from "./features";
import { 
    loadManagerDash, loadManagerDashKeywordDetail, assignKeywordToUser, managerSearchKeyword,
    managerCreateKeywordDetail, managerCheckKeywordDetailExist
 } from "./features/manager";
 import { 
    loadListings, submitListing, updateListingStatus
 } from "./features/listing";
 import { 
    loadSourceTasks
 } from "./features/sourceTask";

export const vibes = [
    getTotalNumber,
    systemInitVibes,
    loadProjects,
    submitKeywordChecker,
    // Authentication
    authRefresh,
    authLogout,
    authLogin(),
    // User
    loadUsers,
    addUser,
    selectUser,
    updateUser,
    // Admin
    loadAdminDash,
    // Manager
    loadManagerDash,
    loadManagerDashKeywordDetail,
    assignKeywordToUser,
    managerSearchKeyword,
    managerCreateKeywordDetail,
    managerCheckKeywordDetailExist,
    // Editor
    loadTasks,
    filterTask,
    loadContents,
    filterContents,
    loadContentDetail,
    updateContent,
    approveContent,
    provisionContent,
    submitTask,
    setFeaturedContent,
    loadAltContents,
    loadBaseKeywords,
    loadPreviewKWList,
    loadSCList,
    downloadSourceContentData,
    submitSourceContentData,
    managerSearchSCKeyword ,
    fetchPreviewKeywordsFromServer,
    deselectContent,
    loadKeywordsList,
    linkContentToKeyword,
    unlinkContentFromKeyword,
    setContentPrimary,
    setContentOriginal,
    searchContentBySlug,
    searchTask,
    loadTaskUsers,
    selectUserTasks,
    loadUnassignedKeyword,
    assignTaskUser,
    prepareContentImage,
    uploadContentImage,
    uploadContent,
    loadUploadBaseList,
    prepareSourcingImage,
    uploadSourcingImage,
    uploadSourcing,
    setTaskFromManager,
    loadSourcingBaseList,
    editorSearchKeyword,
    prepareContentZip,
    unzipContent, uploadContentZipToS3,
    prepareSourcingZip, unzipSourcing, uploadSourcingZipToS3,
    // Listing
    loadListings, submitListing,
    updateListingStatus,
    // SourceTask
    loadSourceTasks
];