import {configureStore} from "@reduxjs/toolkit";
import {createVibesMiddleware} from "@cosmos/vibes";
import {vibes} from './vibes';
import {
    systemReducer,
    adminReducer,
    editorReducer,
    checkerReducer,
    previewReducer,
    taskReducer,
    uploadReducer,
    tagCloudReducer,
    sourcingReducer,
    listingReducer,
    sourceTaskReducer
} from "./features";
import {authReducer} from '@cosmos/runes';
import {connectAxios, getAxiosClient} from "./utils";
import {SearchService} from "./services";
import { managerReducer } from "./features/manager";

const axios = getAxiosClient();
const middleware = [
    createVibesMiddleware(vibes, {
        axios,
        search: new SearchService()
    })
];

export const store = configureStore({
    reducer: {
        system: systemReducer,
        auth: authReducer('pixel'),
        editor: editorReducer,
        admin: adminReducer,
        checker: checkerReducer,
        manager: managerReducer,
        preview: previewReducer,
        task: taskReducer,
        upload: uploadReducer,
        tagCloud: tagCloudReducer,
        sourcing: sourcingReducer,
        listing: listingReducer,
        sourceTask: sourceTaskReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware)
})

connectAxios(axios, store);
