import {createReducer} from "@reduxjs/toolkit";
import {
    LOAD_SOURCE_TASKS, LOAD_SOURCE_TASKS_SUCCESS, SELECT_ST_BASE
} from "../actions/sourcetask";
import {createVibes} from "@cosmos/vibes";
import moment from "moment";

export const sourceTaskReducer = createReducer(
    {
        data: {},
        index:[],
        loading:false, 
        notice: "",
        notify: false,
        noticeType: "error",
        selectedBase: -1
    }
    ,
    builder => {
        builder.addCase(LOAD_SOURCE_TASKS, (state) => {
            state.loading = true;
        })
        .addCase(LOAD_SOURCE_TASKS_SUCCESS, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.index = action.payload.index;
        })
        .addCase(SELECT_ST_BASE, (state, action) => {
            state.loading = true;
            state.selectedBase = action.payload.id;
        })
    }
)

export const loadSourceTasks = createVibes({
    type: [LOAD_SOURCE_TASKS, SELECT_ST_BASE],
    processOptions: {
        successType: LOAD_SOURCE_TASKS_SUCCESS,
    },
    async process({ getState, action, axios }) {
        try {			
            const selectedProject = getState().system.selectedProject;
            const projects = getState().system.projects;
            const project = projects[selectedProject];
            const channel = project?.channel;
            const baseId = getState().sourceTask.selectedBase;
			const { data } = await axios.request({
                method: "post",
                data: {
                    query: `query getSourceTasks($filter: SourceTaskInput) {
                        inCompleteSourceTasks(filter: $filter) { type assignee status type channel kwId payload assigneeProfile {name} keyword {name} }
                        completedSourceTasks(filter: $filter) { type assignee status type channel kwId payload assigneeProfile {name} keyword {name} }
                     }`,
                    variables: {
                        filter :{
                            channel,
                            baseId
                        }
                    },
                },
            });
			const { inCompleteSourceTasks, completedSourceTasks } = data;

            const allSourceTasks = [...inCompleteSourceTasks, ...completedSourceTasks]

			return {
				data: _.keyBy(allSourceTasks,'payload'),
				index: allSourceTasks.map(st=>st.payload)
			}
		} catch (err) {
            throw err;
        }
    },    
});

