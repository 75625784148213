import { createReducer } from "@reduxjs/toolkit";
import { 
    MANAGER_DASH_LOAD, MANAGER_DASH_LOADED, 
    MANAGER_DASH_LOAD_KEYWORD_DETAIL, MANAGER_DASH_LOAD_KEYWORD_DETAIL_SUCCESS, MANAGER_DASH_LOAD_KEYWORD_DETAIL_FAIL,
    ASSIGN_KEYWORD_TO_USER, ASSIGN_KEYWORD_TO_USER_SUCCESS, ASSIGN_KEYWORD_TO_USER_FAIL, 
    MANAGER_CLOSE_NOTIFICATION,
    MANAGER_SEARCH_KEYWORDS, MANAGER_SEARCH_KEYWORDS_SUCCESS, MANAGER_SEARCH_KEYWORDS_FAIL,
    SET_MANAGER_SEARCHED_KD, MANAGER_CHECK_KD_EXIST, MANAGER_CHECK_KD_EXIST_SUCCESS, MANAGER_CHECK_KD_EXIST_FAIL,
    MANAGER_CREATE_KD, MANAGER_CREATE_KD_SUCCESS, MANAGER_CREATE_KD_FAIL, UPDATE_MANAGER_NEW_KW_DIALOG, UPDATE_MANAGER_ASSIGN_DIALOG
} from "../actions/manager";
import { createVibes } from "@cosmos/vibes";

export const managerReducer = createReducer({
    loading: false,
    processing: false,
    code: 0,
    boardsIdx: ["preview", "delegate", "processing", "approval", "priority"],
    boardsData: {
        preview: {
            title: "Ready for Preview",
            desc: "Keyword have scraped content that ready for preview",
            items: [],
            code: "#f89e5d"
        },
        delegate: {
            title: "Ready to Delegate",
            desc: "Keyword already have content which ready assign to writer",
            items: [],
            code: "#fddd43"
        },
        processing: {
            title: "In progress",
            desc: "Keywords in writing content stage",
            items: [],
            code: "#7f5efc"
        },
        approval: {
            title: "Pending Approval",
            desc: "Keyword have content that already edit waiting for approve",
            items: [],
            code: "#6da4f8"
        },
        provision: {
            title: "Pending Provision",
            desc: "Keyword have content that approved waiting for provision",
            items: [],
            code: "#19d3f1"
        },
        priority: {
            title: "Priority Increase Content",
            desc: "High priority to Increase with more Content",
            items: [],
            code: "#f84d4d"
        }
    },
    selectKeywordId: null,
    settingLoading: false,
    keywordDetail: {},
    notice: "",
    notify: false,
    noticeType: "error",
    keywordsOpt: [],
    onNewKeywordDialog: false,
    onAssignDialog: false
}, builder => {
    builder.addCase(MANAGER_DASH_LOAD, state=>{
        state.loading = true;
    }).addCase(MANAGER_DASH_LOADED, (state, action)=>{
        state.loading = false;
        state.boardsData.preview.items = action.payload.preview;
        state.boardsData.delegate.items = action.payload.delegate;
        state.boardsData.processing.items = action.payload.processing;
        state.boardsData.approval.items = action.payload.approval;
        state.boardsData.provision.items = action.payload.provision;
        state.boardsData.priority.items = action.payload.priority;
    }).addCase(MANAGER_DASH_LOAD_KEYWORD_DETAIL, (state, action)=>{
        state.selectKeywordId = action.payload.id;
        state.settingLoading = true;
    }).addCase(MANAGER_DASH_LOAD_KEYWORD_DETAIL_SUCCESS, (state, action)=>{
        state.settingLoading = false;
        state.keywordDetail = action.payload;
    }).addCase(MANAGER_DASH_LOAD_KEYWORD_DETAIL_FAIL, state=>{
        state.settingLoading = false;
    }).addCase(ASSIGN_KEYWORD_TO_USER, state =>{
        state.settingLoading = true;
    }).addCase(ASSIGN_KEYWORD_TO_USER_SUCCESS, (state, action)=>{
        state.settingLoading = false;
        state.keywordDetail.taskAssigned = action.payload.userId;
        state.notice = "Assigned keyword successfully";
        state.notify = true;
        state.noticeType = "success";
        if(action.payload.updKeywordOptList) {
            const kId = state.keywordDetail.id;
            const optList = state.keywordsOpt;
            optList.map(x => {
                if(x.id === kId) {
                    x.keywordDetailData.taskAssigned = action.payload.userId
                }
                return x;
            })
            state.keywordsOpt = optList;
        }
    }).addCase(ASSIGN_KEYWORD_TO_USER_FAIL, (state, action)=>{
        state.settingLoading = false;
        state.notice = action.payload.message;
        state.notify = true;
    }).addCase(MANAGER_CLOSE_NOTIFICATION, state => {
        state.notify = false;
        state.noticeType = "error";
        state.notice = "";
    }).addCase(MANAGER_SEARCH_KEYWORDS, state => {
        state.loading = true;
    }).addCase(MANAGER_SEARCH_KEYWORDS_SUCCESS, (state, action) => {
        state.loading = false;
        state.keywordsOpt = action.payload.keywordsOpt;
    }).addCase(MANAGER_SEARCH_KEYWORDS_FAIL, state => {
        state.loading = false;
    }).addCase(SET_MANAGER_SEARCHED_KD, (state, action) => {
        state.keywordDetail = action.payload.keywordDetail;
        state.selectKeywordId = action.payload.keywordId;
    }).addCase(MANAGER_CREATE_KD, state => {
        state.settingLoading = true;
    }).addCase(MANAGER_CREATE_KD_SUCCESS, (state, action) => {
        state.settingLoading = false;
        state.keywordDetail = action.payload;
        state.selectKeywordId = action.payload.id;
        const optList = state.keywordsOpt;
        optList.map(x => {
            if(x.id === action.payload.id) {
                x.keywordDetailData = action.payload;
            }
            return x;
        })
        state.keywordsOpt = optList;
    }).addCase(MANAGER_CREATE_KD_FAIL, state => {
        state.settingLoading = false;
        state.notice = action.payload.message;
        state.notify = true;
    }).addCase(UPDATE_MANAGER_NEW_KW_DIALOG, (state, action) => {
        state.onNewKeywordDialog = action.status;
    }).addCase(UPDATE_MANAGER_ASSIGN_DIALOG, (state, action) => {
        state.onAssignDialog = action.status;
    }).addCase(MANAGER_CHECK_KD_EXIST, (state, action) => {
        state.loading = true;
    }).addCase(MANAGER_CHECK_KD_EXIST_SUCCESS, (state, action) => {
        state.loading = false;
    }).addCase(MANAGER_CHECK_KD_EXIST_FAIL, (state, action) => {
        state.loading = false;
        state.notice = action.payload.message;
        state.notify = true;
    })
});

export const loadManagerDash = createVibes({
    type: MANAGER_DASH_LOAD,
    latest: true,
    processOptions: {
        successType: MANAGER_DASH_LOADED,
    },
    async process({getState, axios}) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];

        const {data} = await axios.request({
            method: 'POST',
            data: {
                query: `query manager_dash($channel: String) {
                    preview: readyPreview(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                    delegate: readyDelegate(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                    processing(channel: $channel) {
                        id keyword volume potential difficulty contents inventory channeled available provisioned lived 
                    }
                    approval: readyApproval(channel: $channel) {
                        id keyword volume potential difficulty contents inventory channeled available provisioned lived 
                    }
                    provision: readyProvision(channel: $channel) {
                        id keyword volume potential difficulty contents inventory channeled available provisioned lived
                    }
                    priority: thinContents(channel: $channel) {
                        id keyword volume potential difficulty contents
                    }
                }`,
                variables: {
                    channel: project.channel
                }
            }
        });
        return data;
    }
})

export const loadManagerDashKeywordDetail = createVibes({
    type: MANAGER_DASH_LOAD_KEYWORD_DETAIL,
    latest: true,
    processOptions: {
        successType: MANAGER_DASH_LOAD_KEYWORD_DETAIL_SUCCESS,
        failType: MANAGER_DASH_LOAD_KEYWORD_DETAIL_FAIL
    },
    async process({getState, axios, action}) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];
        const kwId = getState().manager.selectKeywordId;
        const {data} = await axios.request({
            method: 'POST',
            data: {
                query: `query loadKeywordDetail($keyword:KeywordFilter){
                    keywordDetail(filter: $keyword) {id channel taskAssigned base}
                }`,
                variables: {
                    keyword: {
                        id: kwId,
                        channel: project.channel
                    },
                }
            }
        });

        if(data && data.keywordDetail) {
            return data.keywordDetail;
        }else {
            throw ("Failed to get keyword detail" + kwId); 
        }
    }
})

export const assignKeywordToUser = createVibes({
    type: ASSIGN_KEYWORD_TO_USER,
    processOptions: {
        successType: ASSIGN_KEYWORD_TO_USER_SUCCESS,
        failType: ASSIGN_KEYWORD_TO_USER_FAIL
    },
    async process({ getState, action, axios }) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];
        const kwId = getState().manager.selectKeywordId;
        const kd = getState().manager.keywordDetail;
        
        const { userId, updKeywordOptList=false } = action.payload;
        try {
            const base = kd?.base;
            if(!base) {
                throw Error("Base is required"); 
            }
            let payload = {
                method: "post",
                data: {
                    query: `mutation task($keyword: Int, $assignee: Int, $channel: String, $base: Int) {
                                assignKeywordToUser(keyword: $keyword, assignee: $assignee, channel: $channel, base: $base) }`,
                    variables: {
                        keyword: kwId,
                        assignee: userId,
                        channel: project.channel,
                        base
                    },
                },
            };
            const data = await axios.request(payload);

            if (data && data.status === 200) {
                return {kwId, userId, updKeywordOptList};
            }else {
                throw Error("Failed to assign keyword to user");
            }
        } catch (err) {
            console.log("Error: ", err)
            throw err;
        }
    },
});

export const managerSearchKeyword = createVibes({
    type: MANAGER_SEARCH_KEYWORDS,
    debounce: 300,
    latest: true,
    processOptions: {
        successType: MANAGER_SEARCH_KEYWORDS_SUCCESS,
        failType: MANAGER_SEARCH_KEYWORDS_FAIL
    },
    async process({getState, action, axios}) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];
        const bases = getState().system.bases;

        const { data } = await axios.request({
            method: "post",
            data: {
                query: `query loadKeywords($filter: KeywordFilter) {
                    keywords(filter: $filter) {
                      id
                      name
                    }
                  }
                  `,
                // query: `query loadKeywords($filter: KeywordFilter) {
                //     keywords(filter: $filter) {
                //       id
                //       name
                //       keywordDetailData {
                //         id
                //         provisioned
                //         available
                //         lived
                //         lastSyncAt
                //         channel
                //         taskAssigned
                //     }
                //     }
                //   }
                //   `,
                variables: {
                    filter: {
                        search: action.search,
                        channel: project.channel
                    }
                }
            }
        });

        if (data && data.keywords) {
            // const keywords = data.keywords;
            const combineKeywords = [];
            data.keywords.map(keyword => {
                Object.values(bases).map(base => {
                    combineKeywords.push({
                        name: keyword.name + " [" + base.name + "]",
                        base: base.id,
                        baseName: base.name,
                        id: keyword.id,
                        keywordName: keyword.name,
                    })
                })
            })

            return {
                keywordsOpt: combineKeywords,
            };
        } else {
            throw new Error();
        }
    }
});

export const managerCreateKeywordDetail = createVibes({
    type: MANAGER_CREATE_KD,
    latest: true,
    async process({ getState, axios, action  } , dispatch, done ) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];

        const kwId = action.payload.kwId
        const {data} = await axios.request({
            method: 'POST',
            data: {
                query: `query loadKeywordDetail($keyword:KeywordFilter){
                    keywordDetail(filter: $keyword) {id channel taskAssigned}
                }`,
                variables: {
                    keyword: {
                        id: kwId,
                        channel: project.channel
                    },
                }
            }
        });

        if(data && data.keywordDetail) {
            dispatch({ type: MANAGER_CREATE_KD_SUCCESS, payload: data.keywordDetail});
            dispatch({ type: UPDATE_MANAGER_NEW_KW_DIALOG,  status: false});
            dispatch({ type: UPDATE_MANAGER_ASSIGN_DIALOG,  status: true});
        }else {
            dispatch({ type: MANAGER_CREATE_KD_FAIL,  
                payload: {
                    message: "Failed to create keyword detail" + kwId
                },
            });
        }
        done();
    }
})

export const managerCheckKeywordDetailExist = createVibes({
    type: MANAGER_CHECK_KD_EXIST,
    async process({ getState, axios, action  } , dispatch, done ) {
        const selectedProject = getState().system.selectedProject;
        const projects = getState().system.projects;
        const project = projects[selectedProject];
        const kwId = action.payload.id;
        const base = action.payload.base;

        const {data} = await axios.request({
            method: 'POST',
            data: {
                query: `query loadKeywordDetail($keyword:KeywordFilter){
                    keywordDetail(filter: $keyword) {id name channel taskAssigned channel base}
                }`,
                variables: {
                    keyword: {
                        id: kwId,
                        channel: project.channel,
                        base
                    },
                }
            }
        });

        if(data && data.keywordDetail) {
            const kd = data.keywordDetail;
            dispatch({type: MANAGER_CHECK_KD_EXIST_SUCCESS});
            kd.displayName = action.payload.name;
            dispatch({type: SET_MANAGER_SEARCHED_KD, payload: {keywordDetail: kd, keywordId: kd.id}});
            dispatch({type: UPDATE_MANAGER_ASSIGN_DIALOG, status: true});
        }else {
            dispatch({ type: MANAGER_CHECK_KD_EXIST_FAIL,  
                payload: {
                    message: "Failed to create keyword detail" + kwId
                },
            });
        }
        done();
    }
})