import { Admin, AdminDash, UserPage } from "./pages";
import { ManagerPage } from "./pages";
import { EditorPage } from "./pages";
import { KeywordPage } from "./pages/manager/keywords";
import { Checker } from "./pages/manager/checker";
import { Preview } from "./pages/manager/preview";
import { ManagerDash } from "./pages/manager/dashboard";
import { Task } from "./pages/manager/task";
import { EditorDash } from "./pages/editor/dashboard";
import { UploadContentPage } from "./pages/editor/uploadContent";
import { SourcingPage } from "./pages/editor/sourcing";
import { Listing } from "./pages/editor/listing";
import { SourceTaskTable } from "./pages/editor/sourceTaskTable";


export const features = [
    {
        name: "editor va",
        path: "/editor",
        permission: ["pixel.content.%"],
        component: EditorPage,
        dash: EditorDash,
        children: [
            { name: "Normal Upload", path: "/upload-content", permission: [], component: UploadContentPage },
            { name: "Fast Track Upload", path: "/sourcing", permission: [], component: SourcingPage },
            { name: "Listing", path: "/listing", permission: [], component: Listing },
            { name: "Source Task", path: "/source-task", permission: [], component: SourceTaskTable },
        ]
    },
    {
        name: "manager",
        path: "/manager",
        permission: ["pixel.content.approval"],
        component: ManagerPage,
        dash: ManagerDash,
        children: [
            { name: "keywords", path: "/keywords", permission: [], component: KeywordPage },
            { name: "checker", path: "/checker", permission: [], component: Checker },
            { name: "preview", path: "/preview", permission: [], component: Preview },
            // { name: "task", path: "/task", permission: [], component: Task }
        ]
    },
    {
        name: "admin",
        path: "/admin",
        permission: ["pixel.admin"], // will be admin only
        component: Admin,
        dash: AdminDash,
        children: [
            {
                name: "user",
                path: "/user",
                permission: [],
                component: UserPage
            }
        ]
    }
];


