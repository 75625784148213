export const GET_TOTAL_NUMBER = "@@content/GET_TOTAL_NUMBER";
export const GET_TOTAL_NUMBER_SUCCESS = "@@content/GET_TOTAL_NUMBER_SUCCESS";

export const LOAD_TASKS = "@@editor/LOAD_TASKS";
export const LOAD_TASKS_SUCCESS = "@@editor/LOAD_TASKS_SUCCESS";
export const LOAD_TASKS_FAIL = "@@editor/LOAD_TASKS_FAIL";

export const SELECT_TASK = '@@editor/SELECT_TASK';

export const LOAD_CONTENTS = '@@editor/LOAD_CONTENTS';
export const LOAD_CONTENTS_SUCCESS = '@@editor/LOAD_CONTENTS_SUCCESS';
export const LOAD_CONTENTS_FAIL = '@@editor/LOAD_CONTENTS_FAIL';
export const UPDATE_CONTENT = '@@editor/UPDATE_CONTENT';
export const UPDATE_CONTENT_ISEDIT = '@@editor/UPDATE_CONTENT_ISEDIT';
export const UPDATE_FILTER = '@@editor/UPDATE_FILTER';
export const CONTENT_FILTERED = '@@editor/CONTENT_FILTERED';
export const TASK_FILTERED = '@@editor/TASK_FILTERED';
export const SELECT_ALT_KEYWORD = '@@editor/SELECT_ALT_KEYWORD';
export const SELECT_ALT_KEYWORD_SUCCESS = '@@editor/SELECT_ALT_KEYWORD_SUCCESS';
export const SELECT_ALT_KEYWORD_FAIL = '@@editor/SELECT_ALT_KEYWORD_FAIL';

export const SHOW_ALERT = '@@editor/SHOW_ALERT';
export const DISMISS_ALERT= '@@editor/DISMISS_ALERT';

export const PROVISION_CONTENT = '@@editor/PROVISION_CONTENT';
export const APPROVE_CONTENT = '@@editor/APPROVE_CONTENT';
export const SAVE_CONTENT = '@@editor/SAVE_CONTENT';
export const CONTENT_UPDATED = '@@editor/CONTENT_UPDATED'
export const SUBMIT_TASK = '@@editor/SUBMIT_TASK';
export const TASK_UPDATED = '@@editor/TASK_UPDATED';
export const LOAD_CONTENT_DETAIL = '@@editor/LOAD_CONTENT_DETAIL';
export const CONTENT_DETAIL_LOADED = '@@editor/CONTENT_DETAIL_LOADED';
export const CONTENT_PROVISIONED = '@@editor/CONTENT_PROVISIONED';
export const SET_FEATURE_CONTENT = '@@editor/SET_FEATURE_CONTENT';
export const FEATURED_UPDATED = '@@editor/FEATURED_UPDATED';
export const UPDATE_AUTHOR = '@@editor/UPDATE_AUTHOR';
export const DESELECT_CONTENT = '@@editor/DESELECT_CONTENT';
export const DESELECT_CONTENT_SUCCESS = '@@editor/DESELECT_CONTENT_SUCCESS';

export const LOAD_KEYWORDS_LIST = '@@editor/LOAD_KEYWORDS_LIST';
export const LOAD_KEYWORDS_LIST_SUCCESS = '@@editor/LOAD_KEYWORDS_LIST_SUCCESS';

export const LINK_REL_KEYWORD = '@@editor/LINK_REL_KEYWORD';
export const LINK_REL_KEYWORD_SUCCESS = '@@editor/LINK_REL_KEYWORD_SUCCESS';
export const LINK_REL_KEYWORD_FAIL = '@@editor/LINK_REL_KEYWORD_FAIL';

export const LINK_CONTENT_TO_KEYWORD = '@@editor/LINK_CONTENT_TO_KEYWORD';
export const LINK_CONTENT_TO_KEYWORD_SUCCESS = '@@editor/LINK_CONTENT_TO_KEYWORD_SUCCESS';
export const LINK_CONTENT_TO_KEYWORD_FAIL = '@@editor/LINK_CONTENT_TO_KEYWORD_FAIL';

export const UNLINK_KEYWORD = '@@editor/UNLINK_KEYWORD';
export const UNLINK_KEYWORD_SUCCESS = '@@editor/UNLINK_KEYWORD_SUCCESS';
export const UNLINK_KEYWORD_FAIL = '@@editor/UNLINK_KEYWORD_FAIL';

export const SET_CONTENT_PRIMARY = '@@editor/SET_CONTENT_PRIMARY';
export const SET_CONTENT_PRIMARY_SUCCESS = '@@editor/SET_CONTENT_PRIMARY_SUCCESS';
export const SET_CONTENT_PRIMARY_FAIL = '@@editor/SET_CONTENT_PRIMARY_FAIL';

export const SET_CONTENT_ORIGINAL = '@@editor/SET_CONTENT_ORIGINAL';
export const SET_CONTENT_ORIGINAL_SUCCESS = '@@editor/SET_CONTENT_ORIGINAL_SUCCESS';
export const SET_CONTENT_ORIGINAL_FAIL = '@@editor/SET_CONTENT_ORIGINAL_FAIL';

export const SEARCH_CONTENT_BY_SLUG = '@@editor/SEARCH_CONTENT_BY_SLUG';
export const SEARCH_CONTENT_BY_SLUG_SUCCESS = '@@editor/SEARCH_CONTENT_BY_SLUG_SUCCESS';
export const SEARCH_CONTENT_BY_SLUG_FAIL = '@@editor/SEARCH_CONTENT_BY_SLUG_FAIL';

export const SEARCH_TASK = '@@editor/SEARCH_TASK';
export const SEARCH_TASK_SUCCESS = '@@editor/SEARCH_TASK_SUCCESS';

export const SELECT_CONTENT = '@@editor/SELECT_CONTENT';
export const SELECT_ALL_CONTENT = '@@editor/SELECT_ALL_CONTENT';
export const UPDATE_SELECT_CONTENT = '@@editor/UPDATE_SELECT_CONTENT';

export const SET_TASK_FROM_MANAGER = '@@editor/SET_TASK_FROM_MANAGER';
export const SET_TASK_FROM_MANAGER_SUCCESS = '@@editor/SET_TASK_FROM_MANAGER_SUCCESS';

export const EDITOR_SEARCH_KEYWORDS = '@@editor/EDITOR_SEARCH_KEYWORDS';
export const EDITOR_SEARCH_KEYWORDS_SUCCESS = '@@editor/EDITOR_SEARCH_KEYWORDS_SUCCESS';